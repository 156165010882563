import './account-navigation.scss';

class Navigation {
    constructor ($customerNavigation) {
        this.$navigation = $customerNavigation;
        this.$trigger = this.$navigation.querySelector('[data-customer="trigger"]');
        this.$content = this.$navigation.querySelector('[data-customer="content"]');
        this.$overlay = this.$navigation.querySelector('[data-customer="overlay"]');
        this.$close = this.$navigation.querySelector('[data-customer="close"]');
        this.mainNav = document.querySelector('[data-nav="root"]');
        this.trustBanner = document.querySelector('[data-trust-banner]');
    }

    initialize () {
        this.setEvents();
    }

    setEvents () {
        this.$trigger.addEventListener('click', () => {
            this.$navigation.classList.remove('is--relative');

            if (this.$overlay.classList.contains('is--active')) {
                this.hide();
                this.bodyScrollLock('disable');
                this.mainNav.classList.remove('is--locked');
                this.arrangeTrustBanner();
            } else {
                this.show();
                this.bodyScrollLock('enable');
                this.mainNav.classList.add('is--locked');
                this.arrangeTrustBanner();
            }
        });

        this.$overlay.addEventListener('click', () => {
            this.hide();
            this.bodyScrollLock('disable');
        });

        this.$navigation.addEventListener('click', (e) => {
            if (e.target.getAttribute('data-customer') === 'close') {
                this.hide();
                this.bodyScrollLock('disable');
            }
        });

        const waitForForm = setInterval(() => {
            this.$content = this.$navigation.querySelector('[data-customer="content"]');

            if (this.$content) {
                clearInterval(waitForForm);

                const $form = this.$content.querySelector('[data-form="root"]');

                if ($form) {
                    import('../form/form').then(({ Form }) => {
                        const $newForm = new Form($form);
                        $newForm.initialize();
                    });
                }
            }
        }, 20);
    }

    bodyScrollLock (status) {
        if (status === 'disable') {
            document.body.style.removeProperty('overflow');
            document.body.style.removeProperty('position');
            document.body.style.removeProperty('top');
            document.body.style.removeProperty('width');
            window.scrollTo(102, this.scrollPosition);
        }

        if (status === 'enable') {
            this.scrollPosition = window.pageYOffset;
            document.body.style.overflow = 'hidden';
            document.body.style.position = 'fixed';
            document.body.style.top = '-' + this.scrollPosition + 'px';
            document.body.style.width = '100%';
        }
    }

    arrangeTrustBanner () {
        if (this.trustBanner) {
            if (this.mainNav.classList.contains('is--locked')) {
                if (window.innerWidth >= 320) {
                    this.trustBanner.style.marginTop = '60px';
                }

                if (window.innerWidth >= 768) {
                    this.trustBanner.style.marginTop = '70px';
                }

                if (window.innerWidth >= 1280) {
                    this.trustBanner.style.marginTop = '102px';
                }

                window.resizeHandler.customFunctions.push(() => {
                    if (this.mainNav.classList.contains('is--locked')) {
                        if (window.innerWidth >= 320) {
                            this.trustBanner.style.marginTop = '60px';
                        }

                        if (window.innerWidth >= 768) {
                            this.trustBanner.style.marginTop = '70px';
                        }

                        if (window.innerWidth >= 1280) {
                            this.trustBanner.style.marginTop = '102px';
                        }
                    } else {
                        this.trustBanner.style.marginTop = '0';
                    }
                });
            } else {
                this.trustBanner.style.marginTop = '0';
            }
        }
    }

    show () {
        const waitInterval = setInterval(() => {
            this.$content = this.$navigation.querySelector('[data-customer="content"]');

            if (this.$content) {
                clearInterval(waitInterval);
                this.$content.classList.add('is--active');
                this.$trigger.classList.add('is--active');
                this.$overlay.classList.add('is--active');
            }
        }, 20);
    }

    hide () {
        this.$content.classList.remove('is--active');
        this.$trigger.classList.remove('is--active');
        this.$overlay.classList.remove('is--active');
    }
}

export { Navigation };

window.addEventListener('content.loaded', (e) => {
    const eventDetails = e.detail;
    const $context = eventDetails.$context;

    const $customerNavigation = $context.querySelector('[data-customer="root"]');

    if ($customerNavigation) {
        const navigation = new Navigation($customerNavigation);
        navigation.initialize();
    }
});
